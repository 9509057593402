<template>
  <div>
    <h1>基本信息</h1>
    <el-form ref="form" :model="patentForm" label-width="80px" label-position="top">
      <el-form-item label="专利名称">
        <el-input disabled v-model="patentForm.patent_name" placeholder="请输入专利名称"></el-input>
      </el-form-item>
      <el-form-item label="企业名称" prop="company_name">
        <el-input disabled v-model="patentForm.company_name" placeholder="请输入企业名称"></el-input>
      </el-form-item>
      <el-form-item label="专利号/申请号">
        <el-input disabled v-model="patentForm.app_code" placeholder="请输入专利号/申请号"></el-input>
      </el-form-item>
      <el-form-item label="数据来源">
        <el-select
          style="width: 100%;"
          clearable
          v-model="patentForm.data_source"
          placeholder="请选择数据来源"
        >
          <el-option label="内部" :value="1"></el-option>
          <el-option label="外部" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="年费内容">
        <el-input v-model="patentForm.content" placeholder="请输入年费内容"></el-input>
      </el-form-item>
      <el-form-item label="年费金额">
        <el-input v-model="patentForm.amount" placeholder="请输入年费金额"></el-input>
      </el-form-item>
      <el-form-item label="截至日期">
        <el-date-picker
          style="width: 100%;"
          v-model="patentForm.end_date"
          type="date"
          placeholder="请选择截至日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="年费状态">
        <el-select style="width: 100%;" clearable v-model="patentForm.status" placeholder="请选择年费状态">
          <el-option label="已缴" :value="1"></el-option>
          <el-option label="待缴" :value="2"></el-option>
          <el-option label="滞纳" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="处理状态">
        <el-select
          style="width: 100%;"
          clearable
          v-model="patentForm.handle_status"
          placeholder="请选择处理状态"
        >
          <el-option label="已处理" :value="1"></el-option>
          <el-option label="未处理" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="年费滞纳金">
        <el-input v-model="patentForm.late_payment" placeholder="请输入年费滞纳金"></el-input>
      </el-form-item>
      <el-form-item label="滞纳金截至日期">
        <el-date-picker
          style="width: 100%;"
          v-model="patentForm.late_end_date"
          type="date"
          placeholder="请选择滞纳金截至日期"
        ></el-date-picker>
      </el-form-item>
      <div style="width: 100%;">
        <h1>年费明细</h1>
      </div>
    </el-form>

    <el-table ref="tableRef" :data="patentForm.annual_fee" style="width: 100%">
      <el-table-column width="50">
        <template slot="header">
          <button type="button" @click="addLine" class="addBtn">
            <i class="addLine"></i>
          </button>
        </template>
        <template slot-scope="scope">
          <button type="button" @click="handleDelete(scope.$index, scope.row)" class="del-btn">
            <i class="delLine"></i>
          </button>
        </template>
      </el-table-column>
      <el-table-column label="年费（N）">
        <template slot-scope="scope">
          <el-input v-model="scope.row.year" placeholder="请输入数字，如：第1年，填写“1”"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="费用（元）">
        <template slot-scope="scope">
          <el-input v-model="scope.row.fee" placeholder="请输入"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="发票附件">
        <template slot-scope="scope">
          <el-upload
            class="upload-demo"
            action="#"
            :http-request="httpRequest"
            :before-upload="beforeAvatarUpload"
            :file-list="fileList"
            :show-file-list="false"
          >
            <el-button
              class="cz info edit"
              @click="upLoad(scope.$index,scope.row.id,scope.row)"
              size="small"
            >上传</el-button>
          </el-upload>
        </template>
      </el-table-column>
    </el-table>
    <div class="foot_TJ">
      <el-button type="primary" :loading="btnLoading" @click="onSubmit()">确定</el-button>
    </div>
  </div>
</template>
    
    <script>
import bus from '../../assets/js/eventBus.js'
import axios from 'axios';
export default {
  props: ['patent_id'],
  data () {
    return {
      btnLoading: false,//提交按钮动画
      file_id: '',
      patentForm: {
        patent_name: '',
        company_name: '',
        app_code: '',
        patent_id: '',
        annual_fee: [],
        data_source: '',
        content: '',
        amount: '',
        end_date: '',
        status: '',
        handle_status: '',
        late_payment: '',
        late_end_date: '',
      },
      fileList: [],

    }
  },
  created () {
    console.log(bus.$on('patent_id'), 789789798)
  },
  methods: {
    getView (patent_id) {
      let id = ''
      if (patent_id) {
        id = patent_id
      } else {
        id = this.$route.query.id
      }
      this.axios.get('/api/project_patent/view_annual_fee', { params: { patent_id: id } }).then((res) => {
        this.patentForm = res.data
      })
    },
    addLine () {
      if (this.patentForm.annual_fee) {
        this.patentForm.annual_fee.push({});
        console.log(this.patentForm)
      }
    },
    handleDelete (index) {
      this.patentForm.annual_fee.splice(index, 1);
    },
    onSubmit () {
      if (this.$route.query.id) {
        this.patentForm.patent_id = this.$route.query.id
      } else {
        this.patentForm.patent_id = this.patent_id
      }
      this.btnLoading = true
      this.axios.post('/api/project_patent/store_annual_fee', { ...this.patentForm }).then((res) => {
        this.$message.success(res.message)
        this.btnLoading = false
      }).catch(() => {
        this.btnLoading = false
      })
    },

    httpRequest (param) {
      console.log(param)
      let file = param.file;
      const form = new FormData();
      form.append("file", file);
      form.append("type", 3);
      form.append("patent_id", this.patentForm.patent_id);
      this.upload("/api/patent/upload_file", form).then((res) => {
        // this.$router.push("/patent/project");
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: res.message,
          });
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    upload (url, params) {
      return axios
        .post(url, params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          return new Promise((resolve, reject) => {
            resolve(res);
          });
        })
        .catch((err) => {
          console.log("err:", err);
        });
    },
    // 上传文件前的过滤
    beforeAvatarUpload (file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return isLt20M;
    },
    upLoad (scope, id, row) {

    },
  },
}
    </script>
    
    <style scoped>
.el-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.el-form-item {
  width: 24%;
  display: inline-block;
  margin-right: 1%;
}
</style>