<template>
  <div>
    <h1>基本信息</h1>
    <el-form ref="form" :model="patentForm" label-width="80px" label-position="top">
      <el-form-item label="专利名称">
        <el-input disabled v-model="patentForm.patent_name" placeholder="请输入专利名称"></el-input>
      </el-form-item>
      <el-form-item label="企业名称" prop="company_name">
        <el-input disabled v-model="patentForm.company_name" placeholder="请输入企业名称"></el-input>
      </el-form-item>
      <el-form-item label="专利号/申请号">
        <el-input disabled v-model="patentForm.app_code" placeholder="请输入专利号/申请号"></el-input>
      </el-form-item>
      <el-form-item label="是否监控">
        <el-select
          style="width: 100%;"
          clearable
          v-model="patentForm.is_monitor"
          placeholder="请选择是否监控"
        >
          <el-option label="是" :value="1"></el-option>
          <el-option label="否" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="费用种类">
        <el-select
          style="width: 100%;"
          clearable
          v-model="patentForm.fee_type"
          placeholder="请选择费用种类"
        >
          <el-option label="申请费" :value="1"></el-option>
          <el-option label="公布印刷费" :value="2"></el-option>
          <el-option label="实质审查费" :value="3"></el-option>
          <el-option label="著录事项变更费" :value="4"></el-option>
          <el-option label="费用" :value="5"></el-option>
          <el-option label="滞纳金" :value="6"></el-option>
          <el-option label="恢复权力请求费" :value="7"></el-option>
          <el-option label="专利权评价报告请求费" :value="8"></el-option>
          <el-option label="专利权无效宣告请求费" :value="9"></el-option>
          <el-option label="复审费" :value="10"></el-option>
          <el-option label="说明书附加费" :value="11"></el-option>
          <el-option label="权利要求附加费" :value="12"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="应缴（元）">
        <el-input v-model="patentForm.payable" placeholder="请输入应缴（元）"></el-input>
      </el-form-item>
      <!-- <el-form-item label="类型">
        <el-select style="width: 100%;" clearable v-model="patentForm.type" placeholder="请选择类型">
        </el-select>
      </el-form-item>-->
      <el-form-item label="减缓比例">
        <el-input v-model="patentForm.reduction_ratio" placeholder="请输入减缓比例"></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-select style="width: 100%;" clearable v-model="patentForm.status" placeholder="请选择状态">
          <el-option label="不提醒" :value="0"></el-option>
          <el-option label="提醒" :value="1"></el-option>
          <el-option label="滞纳" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="处理状态">
        <el-select
          style="width: 100%;"
          clearable
          v-model="patentForm.handle_status"
          placeholder="请选择处理状态"
        >
          <el-option label="已处理" :value="1"></el-option>
          <el-option label="未处理" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="缴费信息">
        <el-input v-model="patentForm.pay_info" placeholder="请输入缴费信息"></el-input>
      </el-form-item>
      <el-form-item label="缴费截至日期">
        <el-date-picker
          style="width: 100%;"
          v-model="patentForm.end_date"
          type="date"
          placeholder="请选择缴费截至日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="剩余天数">
        <el-input v-model="patentForm.surplus_days" placeholder="请输入剩余天数"></el-input>
      </el-form-item>
      <el-form-item label="过期">
        <el-select
          style="width: 100%;"
          clearable
          v-model="patentForm.is_expire"
          placeholder="请选择是否过期"
        >
          <el-option label="是" :value="1"></el-option>
          <el-option label="否" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <div style="width: 100%;">
        <h1>官费信息</h1>
      </div>
      <el-form-item label="申请费">
        <el-input v-model="patentForm.app_fee" placeholder="请输入申请费"></el-input>
      </el-form-item>
      <el-form-item label="公布印刷费">
        <el-input v-model="patentForm.publish_fee" placeholder="请输入公布印刷费"></el-input>
      </el-form-item>
      <el-form-item label="实质审查费">
        <el-input v-model="patentForm.examination_fee" placeholder="请输入实质审查费"></el-input>
      </el-form-item>
      <el-form-item label="著录事项变更费">
        <el-input v-model="patentForm.record_change_fee" placeholder="请输入著录事项变更费"></el-input>
      </el-form-item>
      <el-form-item label="滞纳金">
        <el-input v-model="patentForm.late_payment_fee" placeholder="请输入滞纳金"></el-input>
      </el-form-item>
      <el-form-item label="恢复权力请求费">
        <el-input v-model="patentForm.regain_fee" placeholder="请输入恢复权利请求费"></el-input>
      </el-form-item>
      <el-form-item label="专利权评价报告请求费">
        <el-input v-model="patentForm.report_fee" placeholder="请输入专利权评价报告请求费"></el-input>
      </el-form-item>
      <el-form-item label="专利权无效宣告请求费">
        <el-input v-model="patentForm.invalid_fee" placeholder="请输入专利权无效宣告请求费"></el-input>
      </el-form-item>
      <el-form-item label="复审费">
        <el-input v-model="patentForm.reexamine" placeholder="请输入复审费"></el-input>
      </el-form-item>
      <el-form-item label="说明书附加费">
        <el-input v-model="patentForm.instructions_fee" placeholder="请输入说明书附加费"></el-input>
      </el-form-item>
      <el-form-item label="权利要求附加费">
        <el-input v-model="patentForm.claims_fee" placeholder="请输入权利要求附加费"></el-input>
      </el-form-item>
      <el-form-item label="授权费（元）">
        <el-input v-model="patentForm.authorize_fee" placeholder="请输入授权费（元）"></el-input>
      </el-form-item>
      <el-form-item label="其他费（元）">
        <el-input v-model="patentForm.other_fee" placeholder="请输入其他费（元）"></el-input>
      </el-form-item>
    </el-form>
    <div class="foot_TJ">
      <el-button type="primary" :loading="btnLoading" @click="onSubmit()">确定</el-button>
    </div>
  </div>
</template>
    
    <script>
export default {
  props: ['patent_id'],
  data () {
    return {
      btnLoading: false,//提交按钮
      patentForm: {}
    }
  },
  created () {

  },
  methods: {
    getView (patent_id) {
      let id = ''
      if (this.patent_id) {
        id = patent_id
      } else {
        id = this.$route.query.id
      }
      this.axios.get('/api/project_patent/view_fee', { params: { patent_id: id } }).then((res) => {
        this.patentForm = res.data
      })
    },
    onSubmit () {
      if (this.$route.query.id) {
        this.patentForm.patent_id = this.$route.query.id
      } else {
        this.patentForm.patent_id = this.patent_id
      }
      this.btnLoading = true
      this.axios.post('/api/project_patent/store_fee', this.patentForm).then((res) => {
        this.$message.success('保存成功')
        this.btnLoading = false
        // this.$router.go(-1)
        // this.getView()
      }).catch(() => {
        this.btnLoading = false
      })
    },

    querySearchAsync (queryString, cb) {
      clearTimeout(this.timeout);
      var results = [];
      if (queryString == "") {
        cb(results);
      } else {
        this.axios
          .get("/api/company/select_company", {
            params: {
              keywords: queryString,
            },
          })
          .then((res) => {
            if (res.message == "查询成功") {
              for (let i = 0; i < res.data.length; i++) {
                const element = res.data[i];
                console.log(element);
                results.push({
                  value: element.company_name,
                  id: element.id,
                });
                console.log(results);
              }
              cb(results);
            } else {
              results = [];
              cb(results);
            }
          });
      }
    },
    //点击出现搜索后点击的每一项
    handleSelect (item) {
      console.log(item);
      this.patentForm.com_id = item.id;
      this.patentForm.company_name = item.value;
    },
  },
}
    </script>
    
    <style scoped>
.el-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.el-form-item {
  width: 24%;
  display: inline-block;
  margin-right: 1%;
}
</style>