<template>
  <div class="attachments">
    <div
      class="contents"
      v-loading="loading"
      element-loading-text="附件上传中···"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.5)"
    >
      <h1>附件详情</h1>
      <ul>
        <li>
          <span>企业名称</span>
          <p>{{ this.company_name }}</p>
        </li>
        <li>
          <span>专利名称</span>
          <p>{{ this.patent_name }}</p>
        </li>
      </ul>
      <div class="ImportTop">
        <el-button type="primary" class="ChooseFile" @click="uploadFiles()">请上传附件</el-button>
        <span>（上传文件格式支持pdf，jpg，word，压缩包zip/rar）</span>
      </div>
      <el-table :data="fileList" style="width: 100%">
        <el-table-column prop="name" label="专利附件名称"></el-table-column>
        <el-table-column width="200px" prop="create_time" label="上传时间"></el-table-column>
        <el-table-column width="350px" prop="address" label="操作">
          <template slot-scope="scope">
            <el-button class="cz edit" @click="lookFile(scope.row)" type="text">查看</el-button>
            <el-button class="cz edit" type="text">
              <el-link :underline="false" @click="downFile(scope.row)">下载</el-link>
            </el-button>
            <el-button class="cz del" @click="delFile(scope.row.id)" type="text">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="上传附件" :visible.sync="dialogTableVisible">
        <el-upload
          class="upload-demo"
          ref="upload"
          action="#"
          :http-request="httpRequest"
          :before-upload="beforeAvatarUpload"
          :file-list="showFileList"
          :auto-upload="false"
          :show-file-list="showFile"
        >
          <el-select clearable v-model="patentParams.status" placeholder="请选择专利状态">
            <el-option label="新申请" value="1"></el-option>
            <el-option label="初审合格" value="2"></el-option>
            <el-option label="发明公布" value="3"></el-option>
            <el-option label="发明实审" value="4"></el-option>
            <el-option label="授权" value="5"></el-option>
            <el-option label="驳回" value="6"></el-option>
            <el-option label="撤回" value="7"></el-option>
            <el-option label="专利权维持" value="8"></el-option>
            <el-option label="专利权失效" value="9"></el-option>
          </el-select>
          <el-button type="primary" class="ChooseFile">请上传附件(可多选)</el-button>
          <div slot="tip" class="el-upload__tip">上传txt/jpg/png/doc/xls/pdf/zip文件，单个文件不超过10mb</div>
        </el-upload>

        <el-button type="primary" class="submitUpload" @click="onSubmit()">确定</el-button>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import site from "../../components/global.vue";
export default {
  props: ['patent_id'],
  // inject: ["reload"],
  data () {
    return {
      dialogTableVisible: false,
      loading: false,
      userSite: site.userSite,
      company_name: "",
      patent_name: "",
      tableData: [],
      fileList: [],
      showFileList: [],
      patentParams: {
        status: "",
      },
      showFile: true,
    };
  },
  methods: {
    uploadFiles () {
      this.dialogTableVisible = true
    },
    downFile (row) {
      window.open(this.userSite + row.url)
    },
    onSubmit () {
      this.$refs.upload.submit();
    },
    getFileList (patent_id) {
      let id = ''
      if (patent_id) {
        id = patent_id
      } else {
        id = this.$route.query.id
      }
      this.axios
        .get("/api/patent/view_file", {
          params: {
            patent_id: id,
          },
        })
        .then((res) => {
          this.company_name = res.data.company_name;
          this.patent_name = res.data.patent_name;
          this.fileList = res.data.file_list.data;
        });
    },
    httpRequest (param) {
      let file = param.file;
      const form = new FormData();
      let id = ''
      if (this.patent_id) {
        id = this.patent_id
      } else {
        id = this.$route.query.id
      }
      console.log(id, 'idididid')
      form.append("file", file);
      form.append("type", "1");
      form.append("patent_id", id);
      form.append("status", this.patentParams.status);
      this.upload("/api/patent/upload_file", form).then((res) => {
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: res.message,
          });
          this.loading = false;
          this.getFileList(id)
          this.$refs.upload.clearFiles();
          this.dialogTableVisible = false
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
          this.loading = false;
          this.$refs.upload.clearFiles();
        }
      });
    },
    upload (url, params) {
      this.loading = true;
      return axios
        .post(url, params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          return new Promise((resolve, reject) => {
            resolve(res);
          });
        })
        .catch((err) => {
          console.log("err:", err);
        });
    },
    // 上传文件前的过滤
    beforeAvatarUpload (file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return isLt20M;
    },
    lookFile (row) {
      window.open(this.userSite + row.url)
    },
    uploadFile (row) {
      console.log(this.userSite + this.fileList.url)
    },
    delFile (id) {
      this.$confirm('此操作将永久删除该附件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.delete("/api/patent/delete_file", {
          params: {
            id: id,
          },
        }).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.getFileList()
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
  },
};
</script>

<style scoped="scoped">
.attachments ul li {
  margin-top: 20px;
}

.attachments ul li span {
  color: #cbcfe3;
  margin-right: 20px;
  float: left;
}

.attachments ul li p {
  display: inline-block;
  width: 80%;
  margin: 0;
  color: #909399;
}

.upload-demo {
  display: inline-block;
}

.ImportTop {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.ChooseFile {
  width: 370px;
  height: 40px;
  background: #edeef4 !important;
  color: #909399 !important;
  border: none;
}

.UploadFile {
  width: 88px;
  height: 40px;
  background: #0f38ff !important;
  color: #fff !important;
  border: none;
  margin-left: 20px;
}

.el-upload-list {
  display: inline-block;
}

.el-upload-list__item-name {
  margin-top: 20px;
}
.ImportTop span {
  color: #a6abc7;
}
div/deep/.el-button--text {
  background: #f2f3f9;
  padding: 7px 15px !important;
  font-weight: bold;
}
div/deep/.el-form-item {
  margin: 0;
}
div/deep/.el-form-item__content {
  margin: 0;
  margin-right: 20px;
}
div/deep/.el-icon-loading {
  font-size: 20px;
  color: #fff;
}
div/deep/.el-loading-text {
  color: #fff;
  font-size: 12px;
}
.submitUpload {
  display: block;
  margin: 15px auto;
}
div/deep/.el-upload {
  display: flex;
  align-items: center;
}
.el-select {
  margin-right: 20px;
}
.upload-demo {
  width: 100%;
}
div/deep/.el-dialog__body {
  padding: 20px !important;
}
div/deep/.el-dialog__title {
  font-weight: bold;
}
</style>
