<template>
  <div>
    <!-- 案卷管理新增/编辑 -->
    <h1>基本信息</h1>
    <el-form ref="form" :model="patentForm" label-width="80px" label-position="top">
      <el-form-item label="专利名称">
        <el-input v-model="patentForm.patent_name" placeholder="请输入专利名称"></el-input>
      </el-form-item>

      <el-form-item label="企业名称" prop="company_name">
        <el-autocomplete
          class="inline-input"
          v-model="patentForm.company_name"
          :fetch-suggestions="querySearchAsync"
          @select="handleSelect"
          placeholder="请输入企业名称"
        ></el-autocomplete>
      </el-form-item>
      <el-form-item label="专利号/申请号">
        <el-input v-model="patentForm.app_code" placeholder="请输入专利号/申请号"></el-input>
      </el-form-item>
      <el-form-item label="申请日期">
        <el-date-picker
          style="width: 100%"
          v-model="patentForm.app_date"
          type="date"
          placeholder="选择日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="标签" style="width: 74%">
        <el-select
          style="width: 100%"
          value-key="tag"
          multiple
          v-model="taglist"
          placeholder="请选择标签"
        >
          <el-option
            v-for="(item, index) in options"
            :label="item.name"
            :value="item.id"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="内部编号">
        <el-input v-model="patentForm.internal_code" placeholder="请输入内部编号"></el-input>
      </el-form-item>
      <el-form-item label="专利类型">
        <el-select
          style="width: 100%"
          clearable
          v-model="patentForm.patent_type"
          placeholder="请选择专利类型"
        >
          <el-option label="发明专利" :value="1"></el-option>
          <el-option label="实用新型" :value="2"></el-option>
          <el-option label="外观设计" :value="3"></el-option>
          <el-option label="植物新品种" :value="4"></el-option>
          <el-option label="国家新药" :value="5"></el-option>
          <el-option label="集成电路布图设计专有权" :value="6"></el-option>
          <el-option label="国家级农作物品种" :value="7"></el-option>
          <el-option label="国家一级中药保护品种" :value="8"></el-option>
          <el-option label="其中：国防专利" :value="9"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="专利类型（内部）">
        <el-input v-model="patentForm.patent_type_internal" placeholder="请输入专利类型（内部）"></el-input>
      </el-form-item>
      <el-form-item label="专利状态">
        <el-select style="width: 100%" clearable v-model="patentForm.status" placeholder="请选择专利状态">
          <el-option label="新申请" :value="1"></el-option>
          <el-option label="初审合格" :value="2"></el-option>
          <el-option label="发明公布" :value="3"></el-option>
          <el-option label="发明实审" :value="4"></el-option>
          <el-option label="授权" :value="5"></el-option>
          <el-option label="驳回" :value="6"></el-option>
          <el-option label="撤回" :value="7"></el-option>
          <el-option label="专利权维持" :value="8"></el-option>
          <el-option label="专利权失效" :value="9"></el-option>
          <el-option label="审查意见" :value="10"></el-option>
          <el-option label="补正" :value="11"></el-option>
          <el-option label="专利证书" :value="12"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="专利状态（内部）">
        <el-input v-model="patentForm.patent_status_internal" placeholder="请输入专利状态（内部）"></el-input>
      </el-form-item>
      <el-form-item label="公开号">
        <el-input v-model="patentForm.public_code" placeholder="请输入公开号"></el-input>
      </el-form-item>
      <el-form-item label="公开日期">
        <el-date-picker
          style="width: 100%"
          v-model="patentForm.public_date"
          type="date"
          placeholder="请选择公开日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="多申请人">
        <el-input v-model="patentForm.applicant" placeholder="请输入多申请人，一般为公司名称"></el-input>
      </el-form-item>
      <el-form-item label="代理人">
        <el-input v-model="patentForm.agent" placeholder="请输入代理人"></el-input>
      </el-form-item>
      <el-form-item label="发明人">
        <el-input v-model="patentForm.inventor" placeholder="请输入发明人，如 张三，李四..."></el-input>
      </el-form-item>
      <el-form-item label="业务类型">
        <el-input v-model="patentForm.business_type" placeholder="请输入业务类型"></el-input>
      </el-form-item>
      <el-form-item label="客户经理">
        <el-input v-model="patentForm.custom_manager" placeholder="请输入客户经理"></el-input>
      </el-form-item>
      <el-form-item label="工程师">
        <el-input v-model="patentForm.engineer" placeholder="请输入工程师" />
        <!-- </el-date-picker> -->
      </el-form-item>
      <el-form-item label="流程人">
        <el-input v-model="patentForm.process" placeholder="请输入流程人"></el-input>
      </el-form-item>
      <el-form-item label="下证情况">
        <el-date-picker
          style="width: 100%"
          v-model="patentForm.certificate_date"
          type="date"
          placeholder="请选择下证情况日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="证书分发情况">
        <el-input v-model="patentForm.certificate_situation" placeholder="请输入证书分发情况"></el-input>
      </el-form-item>
      <el-form-item label="专利补助">
        <el-input v-model="patentForm.subsidy" placeholder="请输入专利补助"></el-input>
      </el-form-item>
      <!-- <el-form-item label="专利补写">
        <el-input v-model="patentForm.supplement_patent_id" placeholder="请输入专利补写"></el-input>
      </el-form-item>-->
      <el-form-item label="备注" style="width: 100%">
        <el-input type="textarea" v-model="patentForm.desc" placeholder="请输入备注" rows="5"></el-input>
      </el-form-item>
    </el-form>
    <div class="foot_TJ">
      <el-button type="primary" :loading="btnLoading" @click="onSubmit()">确定</el-button>
    </div>
  </div>
</template>

<script>
// import bus from '../../assets/js/eventBus.js'
export default {
  props: ['patent_id'],
  data () {
    return {
      btnLoading: false,//提交按钮动画
      taglist: [], //标签多选列表
      options: [
        {
          name: '系统重复提交',
          id: '1',
        },

        {
          name: '专利转让/变更',
          id: '2',
        },

        {
          name: '非正常申请重写',
          id: '3',
        },
        {
          name: '非正常申请撤回',
          id: '4',
        },
        {
          name: '退费',
          id: '5',
        },
        {
          name: '驳回/撤回重写',
          id: '6',
        },
        {
          name: '假专利',
          id: '7',
        },
        {
          name: '代提交',
          id: '8',
        },
      ],
      patentForm: {},
      storeMessage: '',
    }
  },
  created () {
    if (this.$route.query.id) {
      this.storeMessage = '保存成功'
    } else {
      this.storeMessage = '保存成功,现在可以编辑费用、年费、中间件、附件'
    }
  },
  methods: {
    patentView () {
      this.axios
        .get('/api/project_patent/view', {
          params: { id: this.$route.query.id },
        })
        .then((res) => {
          this.patentForm = res.data
          if (res.data.tag) {
            this.taglist = res.data.tag.split(',')
          }
        })
    },
    onSubmit () {
      this.patentForm.tag = this.taglist.join()
      this.btnLoading = true
      this.axios
        .post('/api/project_patent/store', this.patentForm)
        .then((res) => {
          if (res.data.id) {
            this.$emit('update:patent_id', res.data.id)
          }
          this.$message.success(
            this.storeMessage
          )
          this.btnLoading = false
          // this.$router.go(-1)
        })
        .catch((res) => {
          this.$message.error(res.message)
          this.btnLoading = false
        })
    },

    querySearchAsync (queryString, cb) {
      clearTimeout(this.timeout)
      var results = []
      if (queryString == '') {
        cb(results)
      } else {
        this.axios
          .get('/api/company/select_company', {
            params: {
              keywords: queryString,
            },
          })
          .then((res) => {
            if (res.message == '查询成功') {
              for (let i = 0; i < res.data.length; i++) {
                const element = res.data[i]
                console.log(element)
                results.push({
                  value: element.company_name,
                  id: element.id,
                })
                console.log(results)
              }
              cb(results)
            } else {
              results = []
              cb(results)
            }
          })
      }
    },
    //点击出现搜索后点击的每一项
    handleSelect (item) {
      console.log(item)
      this.patentForm.com_id = item.id
      this.patentForm.company_name = item.value
    },
  },
}
</script>

<style scoped>
.el-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.el-form-item {
  width: 24%;
  display: inline-block;
  margin-right: 1%;
}
</style>
