<template>
  <div>
    <Header :title="title" :back="back" index="首页" titleOne="工程专利" :beforeTitle="title" />
    <div class="content">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="案卷信息" name="patent_info">
          <Info ref="info" :patent_id.sync="patent_id" />
        </el-tab-pane>
        <el-tab-pane :disabled="isDis" label="专利费用" name="patent_cost">
          <Cost ref="cost" :patent_id.sync="patent_id" />
        </el-tab-pane>
        <el-tab-pane :disabled="isDis" label="专利年费" name="patent_fee">
          <Fee ref="fee" :patent_id.sync="patent_id" />
        </el-tab-pane>
        <el-tab-pane :disabled="isDis" label="中间文件" name="center">
          <Center ref="center" :patent_id.sync="patent_id" />
        </el-tab-pane>
        <el-tab-pane :disabled="isDis" label="专利附件" name="patent_app">
          <App ref="app" :patent_id.sync="patent_id" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Info from '../../components/patentEditTabs/patentInfo.vue'
import Cost from '../../components/patentEditTabs/patentCost.vue'
import Fee from '../../components/patentEditTabs/patentFee.vue'
import Center from '../../components/patentEditTabs/patentCenter.vue'
import App from '../../components/patentEditTabs/patentApp.vue'
export default {
  data () {
    return {
      patentTabs: true,
      title: '',
      back: '返回',
      activeName: 'patent_info',
      patent_id: '',
      isDis: true,
    }
  },
  components: {
    Info,
    Cost,
    Fee,
    Center,
    App,
  },
  watch: {
    patent_id (val) {
      console.log(val, 'val 9999')
      if (val) {
        this.isDis = false
      } else {
        this.isDis = true
        // this.patent_id = val
      }
    }
  },
  created () {
    // console.log(this.patent_id, 888)
    if (this.$route.query.id) {
      this.isDis = false
    } else {
      this.isDis = true
    }
    this.activeName = this.$route.query.type
    if (this.activeName == 'patent_info') {
      this.title = '案卷管理'
      this.$nextTick(() => {
        if (this.$route.query.id) {
          this.$refs.info.patentView()
        }
      })
    } else if (this.activeName == 'center') {
      this.title = '中间文件'
      this.$nextTick(() => {
        this.$refs.center.getView()
      })
    } else if ((this, this.activeName == 'patent_fee')) {
      this.title = '专利年费'
      this.$nextTick(() => {
        this.$refs.fee.getView()
      })
    } else if (this.activeName == 'patent_cost') {
      this.title = '专利费用'
      this.$nextTick(() => {
        this.$refs.cost.getView()
      })
    } else if (this.activeName == 'patent_app') {
      this.title = '专利附件'
      this.$nextTick(() => {
        this.$refs.app.getFileList()
      })
    }
  },
  methods: {
    getPatentId (id) {
      this.patent_id = id
    },
    handleClick (tab) {
      this.title = tab.label
      if (this.activeName == 'patent_info') {
        this.$refs.info.patentView()
      } else if (this.activeName == 'center') {
        this.$refs.center.getView(this.patent_id)
      } else if ((this, this.activeName == 'patent_fee')) {
        this.$refs.fee.getView(this.patent_id)
      } else if (this.activeName == 'patent_cost') {
        this.$refs.cost.getView(this.patent_id)
      } else if (this.activeName == 'patent_app') {
        this.$refs.app.getFileList(this.patent_id)
      }
    },
  },
}
</script>

<style scoped></style>
