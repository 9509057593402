<template>
  <div>
    <h1>基本信息</h1>
    <el-form ref="form" :model="patentForm" label-width="80px" label-position="top">
      <el-form-item label="专利名称">
        <el-input disabled v-model="patentForm.patent_name" placeholder="请输入专利名称"></el-input>
      </el-form-item>
      <el-form-item label="企业名称" prop="company_name">
        <el-input disabled v-model="patentForm.company_name" placeholder="请输入企业名称"></el-input>
      </el-form-item>
      <el-form-item label="专利号/申请号">
        <el-input disabled v-model="patentForm.app_code" placeholder="请输入专利号/申请号"></el-input>
      </el-form-item>
      <el-form-item label="通知书名称">
        <el-input v-model="patentForm.name" placeholder="请输入通知书名称"></el-input>
      </el-form-item>
      <el-form-item label="发文日期">
        <el-date-picker
          style="width: 100%;"
          v-model="patentForm.issue_date"
          type="date"
          placeholder="请选择发文日期"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="截至日期">
        <el-date-picker
          style="width: 100%;"
          v-model="patentForm.end_date"
          type="date"
          placeholder="请选择截至日期"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-form-item>
    </el-form>
    <div class="foot_TJ">
      <el-button type="primary" :loading="btnLoading" @click="onSubmit()">确定</el-button>
    </div>
  </div>
</template>
    
<script>
export default {
  props: ['patent_id'],

  data () {
    return {
      btnLoading: false,//提交按钮动画
      patentForm: {
        patent_id: '',
        patent_name: '',
        company_name: '',
        app_code: '',
        name: '',
      }
    }
  },
  created () {
    // if (this.$route.query.id) {
    //   this.getView()
    // }
  },
  methods: {
    getView (patent_id) {
      let id = ''
      if (this.patent_id) {
        id = patent_id
      } else {
        id = this.$route.query.id
      }
      this.axios.get('/api/project_patent/view_middle_info', { params: { patent_id: id } }).then((res) => {
        this.patentForm = res.data
      })
    },
    onSubmit () {
      if (this.$route.query.id) {
        this.patentForm.patent_id = this.$route.query.id
      } else {
        this.patentForm.patent_id = this.patent_id
      }
      this.btnLoading = true
      this.axios.post('/api/project_patent/store_middle_info', {
        // patent_id: this.$route.query.id,
        ...this.patentForm
      })
        .then((res) => {
          this.$message.success('保存成功')
          this.btnLoading = false
          // this.$router.go(-1)
        })
        .catch((r) => {
          this.$message.error(r.message)
          this.btnLoading = false
        })
    },
    querySearchAsync (queryString, cb) {
      clearTimeout(this.timeout);
      var results = [];
      if (queryString == "") {
        cb(results);
      } else {
        this.axios
          .get("/api/company/select_company", {
            params: {
              keywords: queryString,
            },
          })
          .then((res) => {
            if (res.message == "查询成功") {
              for (let i = 0; i < res.data.length; i++) {
                const element = res.data[i];
                console.log(element);
                results.push({
                  value: element.company_name,
                  id: element.id,
                });
                console.log(results);
              }
              cb(results);
            } else {
              results = [];
              cb(results);
            }
          });
      }
    },
    //点击出现搜索后点击的每一项
    handleSelect (item) {
      console.log(item);
      this.patentForm.com_id = item.id;
      this.patentForm.company_name = item.value;
    },
  },
}
    </script>
    
    <style scoped>
.el-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.el-form-item {
  width: 24%;
  display: inline-block;
  margin-right: 1%;
}
</style>